<template lang="pug">
  v-container#user-profile(fluid='' tag='section' style="padding: 0px;")
    v-dialog(v-model='dialogConfig' width='500')
      v-card
        v-card-title.text-h5.grey.lighten-2
          h3 Configuración de la Empresa
        v-card-text
          v-row(style="margin-top: 15px;")
            //- <!--v-col(cols="12" md="12" style="padding: 0px 10px 0px 20px;")-->
            //-   <!--v-checkbox(label="MÓDULO PARA RESTAURANTE" style="margin: 0px;" v-model="profile.restaurant" title="Verifica el ingreso de combos con su respectivo costo para facturación.")-->
            v-col(cols="12" md="12" style="padding: 0px 10px 0px 20px;")
              v-checkbox(label="REQUIERE IMPRESIÓN DE FACTURA Y TICKET" style="margin: 0px;" v-model="profile.print" title="Activa la impresión de facturas y tickes en impresora térmica.")
            v-col(cols="12" style="padding: 0px 10px 0px 20px;")
              v-checkbox(label="CAMPOS ADICIONALES PARA LA FACTURA" style="margin: 0px;" v-model="profile.aditionalField" title="Valida los campos adicionales a visualizar dentro de la factura aprobada por el SRI.")
            //- <!--v-col(cols="12" style="padding: 0px 10px 0px 20px;")-->
            //-   <!--v-checkbox(label="PRECIO MAYORISTA Y MINORISTA" style="margin: 0px;" v-model="profile.productPrice" title="Activa la funcionalidad para visualizar los precios de mayorista y minorista dentro de facturación.")-->
            //- <!--v-col(cols="12" style="padding: 0px 10px 0px 20px;")-->
            //-   <!--v-checkbox(label="NOTA DE VENTA DENTRO DE FACTURACIÓN" style="margin: 0px;" v-model="profile.saleNoteBilling" title="Activa la funcionalidad para generar una nota de venta en lugar de factura facilitando el proceso de emsión de comprobante.")-->
            //- <!--v-col(cols="12" style="padding: 0px 10px 0px 20px;")-->
            //-   <!--v-checkbox(label="MANEJAR INVENTARIO NEGATIVO" style="margin: 0px;" v-model="profile.negativeInventory" title="Activa la funcionalidad para manejar inventario negativo sin bloquear una factura en el caso de no tener productos en stock.")-->
            v-col(cols="12" style="padding: 0px 10px 0px 20px;")
              v-checkbox(label="GENERAR FACTURA AUTÓMATICA EN ERROR" style="margin: 0px;" v-model="profile.genAutomaticInvoice" title="Activa la funcionalidad para regenerar automáticamente una factura electrónica en caso de tener error al crearla.")
        v-divider
        v-card-actions
          v-spacer
          v-btn(color='red' text='' @click='dialogConfig = false')
            | SALIR
          v-btn(color='primary' @click='updateProfile')
            | GUARDAR
    v-dialog(v-model='dialog' scrollable='' max-width='400px')
      v-card
        v-card-title
          h4 Selecciona una Imagen
        v-divider
        v-card-text(style='text-align: center;')
          img(:src='imageUrl' height='150' v-if='imageUrl' alt='Seleccione una imagen')
          v-text-field(label='Seleccionar' @click='pickFile' v-model='imageName' prepend-icon='mdi-upload' accept=".png, .jpg, .jpeg")
          input(type='file' style='display: none' ref='image' accept='image/*' @change='onFilePicked')
        v-divider
        v-card-actions
          v-spacer
            v-btn(color='red' text='' @click='dialog=false') CANCELAR
            v-btn(color='primary' @click='upload') ACEPTAR
    v-snackbar(v-model='snackbar.show' :timeout='5000' :color='snackbar.color' top='' right='' :multi-line='multiLine')
      | {{ snackbar.text }}
      template(v-slot:action='{ attrs }')
        v-btn(dark='' text='' icon='' @click='snackbar.show = false' v-bind='attrs')
          v-icon mdi-close
    //- v-snackbar(:timeout='5000' top='' right='' :color='snackbar.color' v-model='snackbar.show')
    //-   | {{ snackbar.text }}
    //-   v-btn(dark='' text='' @click.native='snackbar.show = false' icon='' style='background: transparent;')
    //-     v-icon mdi-close
    v-row(justify='center' style="width: 100%; margin: 0px;")
      v-col(cols='12' md='12' style="padding: 0px;")
        base-material-card.px-5.py-3(icon='mdi-folder' title='Perfil de Empresa' style='min-height: 100vh;' flat)
          v-form(ref='formProfile' v-model='validProfile' lazy-validation='' autocomplete="random-string")
            v-alert(v-model="alertDataProfile" dismissible dense='' border='left' type='info')
              | Si ha utilizado otros sistemas de {{ }}
              strong FACTURACIÓN ELECTRÓNICA {{ }}
              | verifique que los {{" "}}
              strong SECUENCIALES {{ }}
              | sean correctos en función de sus comprobantes electrónicos anteriores.
            v-row
              v-col(cols="12" md="6")
                v-row
                  h3(style="padding-bottom: 20px; padding-top: 20px;") Datos de Perfil
                v-row
                  v-col(cols='12' md="4")
                    v-text-field.purple-input(:rules="requeridos" label='RUC' type="text" maxlength="13" @keypress="isNumber($event)" v-model='profile.rucEstablecimieto')
                  v-col(cols='12' md="8")
                    v-text-field.purple-input(:rules="requeridos" label='Dirección de la Matriz' v-model='profile.direccionEstablecimiento' type='text' maxlength="300")
                  v-col(cols='12' md='12')
                    v-text-field.purple-input(:rules="requeridos" label='Razón Social' v-model='profile.razonSocial' type='text' maxlength="300")
                  v-col(cols='12' md="12")
                    v-text-field.purple-input(label='Nombre Comercial' v-model='profile.nombreComercial' type='text' maxlength="300")
                  // v-col(cols='12' md='4')
                    v-text-field.purple-input(:rules="requeridos" label='Nombre del establecimiento' v-model='profile.nombreEstablecimiento')
                  v-col(cols='12' md='7')
                    v-text-field.purple-input(:rules="requeridos" label='Correo del establecimiento' v-model='profile.correoEstablecimiento')
                  v-col(cols='12' md='5')
                    v-text-field.purple-input(:rules="requeridos" label='Teléfono del establecimiento' type="text" @keypress="isNumber($event)" v-model='profile.telefonoEstablecimiento')
                  //v-col(cols='12' md='6')
                    v-text-field.purple-input(label='Nombre del Representante' v-model='profile.nombreRepresentante')
                  //v-col(cols='12' md='6')
                    v-text-field.purple-input(label='Apellido del Representante' v-model='profile.apellidoRepresentante')
                  //v-col(cols='12')
                    v-textarea.purple-input(label='Descripción' value='Breve descripción del establecimiento.' v-model='profile.descripcionEstablecimiento')
                  <!--v-col(cols="12" md="4")-->
                  <!--v-checkbox(label="RESTAURANTE" style="margin: 0px;" v-model="profile.restaurant")-->
                  <!--v-col(cols="12" md="4")-->
                  <!--v-checkbox(label="IMPRESIÓN" style="margin: 0px;" v-model="profile.print")-->
                  <!--v-col(cols="12" md="4")-->
                  <!--v-checkbox(label="CONTADOR/A" style="margin: 0px;" v-model="profile.counter")-->
              v-col(cols="12" md="3")
                h3(style="padding-bottom: 20px; padding-top: 20px; text-align: center;") Secuenciales
                v-row
                  v-col(cols="12" md="12")
                    v-text-field(v-model='profile.estab' :rules="requeridos" label="Código Establecimiento" type="text" maxlength="3" @keypress="isNumber($event)" title="Código del establecimiento de emisión de comprobantes")
                  v-col(cols="12" md="12")
                    v-text-field(v-model='profile.ptoEmi' :rules="requeridos" label="Código del Punto de Emisión" type="text" maxlength="3" @keypress="isNumber($event)" title="Punto de emisión de comprobantes")
                  <!--v-col(cols='12' md='6')-->
                    <!--v-text-field.purple-input(:disabled="notesSaleLst.length > 0" type="text" @keypress="isNumber($event)"   min="1" :rules="requeridos" label='Sec. Nota de Venta' v-model='profile.secuenciales.notaventa')-->
                  <!--v-col(cols='12' md='6')-->
                    <!--v-text-field.purple-input(:disabled="notesPurchaseLst.length > 0" type="text" @keypress="isNumber($event)"   min="1" :rules="requeridos" label='Sec. Nota de Compra' v-model='profile.secuenciales.notacompra')-->
                  v-col(cols='12' md='12')
                    v-text-field.purple-input(:disabled="facturasSaleLst.length > 0" type="text" @keypress="isNumber($event)"   min="1" :rules="requeridos" label='Sec. Factura de Venta' v-model='profile.secuenciales.facventa')
                  //v-col(cols='12' md='6')
                    v-text-field.purple-input(type="text" @keypress="isNumber($event)"   min="1" :rules="requeridos" label='Sec. Factura de Compra' v-model='profile.secuenciales.faccompra')
                  v-col.text-center(cols='12' md="12")
                    v-btn.mr-0(color='primary' @click='updateProfile' :loading="loading" style="width: 100%;")
                      | GUARDAR CAMBIOS
              v-col(cols="12" md="3" style="padding-top: 50px;")
                base-material-card.v-card-profile(v-bind:avatar='profile.urlImg' title='Logo de la Empresa' style="min-height: 220px;")
                  v-card-text.text-center
                    v-btn.mr-0(color='primary' rounded='' @click='dialog = true')
                      | SUBIR LOGO
                v-row
                  v-col.text-center(cols="12")
                    v-btn.mr-0(color='secondary' @click='dialogConfig = true')
                      | CONFGURACIONES
</template>

<script>
  // import firebase from 'firebase'
  import firebase from 'firebase/app'
  import 'firebase/database'
  import 'firebase/auth'
  import 'firebase/storage'
  import * as functions from '../../../functions'

  export default {
    data: () => ({
      dialogConfig: false,
      printBan: false,
      dialogPrint: false,
      alertDataProfile: true,
      loading: false,
      dialog: false,
      imageUrl: '',
      imageName: '',
      validProfile: true,
      print: false,
      requeridos: [
        value => !!value || 'Requerido',
      ],
      snackbar: {
        show: false,
        text: '',
        color: '',
      },
      currentUserId: firebase.auth().currentUser.uid,
      userProfilePreview: null,
      profile: {
        puertoimpresora: '',
        nombreEstablecimiento: '',
        correoEstablecimiento: '',
        telefonoEstablecimiento: '',
        razonSocial: '',
        nombreRepresentante: '',
        apellidoRepresentante: '',
        direccionEstablecimiento: '',
        rucEstablecimieto: '',
        descripcionEstablecimiento: '',
        nombreComercial: '',
        secuenciales: {
          proforma: '1',
          notacompra: '1',
          notaventa: '1',
          faccompra: '1',
          facventa: '1',
        },
        estab: '',
        ptoEmi: '',
        urlImg: '',
        restaurant: false,
        counter: false,
        aditionalField: false,
        productPrice: false,
        saleNoteBilling: false,
        negativeInventory: false,
        genAutomaticInvoice: false,
      },
    }),
    computed: {
      facturasSaleLst () {
        return this.$store.state.factura.facturaSaleLst
      },
      notesSaleLst () {
        return this.$store.state.factura.noteSaleLst
      },
      notesPurchaseLst () {
        return this.$store.state.factura.notePurchaseLst
      },
      profileLoaded () {
        return this.$store.state.profile.profileLoaded
      },
      lstProfileLoaded () {
        return this.$store.state.profile.lstProfiles
      },
    },
    watch: {
      profileLoaded () {
        if (!this.userProfilePreview) {
          if (this.profileLoaded) this.profile = this.profileLoaded
          if (!this.profile.urlImg || this.profile.urlImg === '') {
            this.profile.urlImg = 'https://firebasestorage.googleapis.com/v0/b/j2rinventor.appspot.com/o/default%2Fit_logo.png?alt=media&token=401e29bd-a347-440b-b369-94e75e5dd0fb'
          }
          if (!this.profile.estab || this.profile.estab === '') {
            this.profile.estab = '001'
          }
          if (!this.profile.ptoEmi || this.profile.ptoEmi === '') {
            this.profile.ptoEmi = '001'
          }
          if (!this.profile.secuenciales) {
            this.profile.secuenciales = {
              proforma: '1',
              notacompra: '1',
              notaventa: '1',
              faccompra: '1',
              facventa: '1',
            }
          }
        } else {
          this.userProfilePreview = null
        }
      },
    },
    mounted () {
      this.alertDataProfile = true
      if (this.profileLoaded) this.profile = this.profileLoaded
      if (!this.profile.urlImg || this.profile.urlImg === '') {
        this.profile.urlImg = 'https://firebasestorage.googleapis.com/v0/b/j2rinventor.appspot.com/o/default%2Fit_logo.png?alt=media&token=401e29bd-a347-440b-b369-94e75e5dd0fb'
      }
      if (!this.profile.estab || this.profile.estab === '') {
        this.profile.estab = '001'
      }
      if (!this.profile.ptoEmi || this.profile.ptoEmi === '') {
        this.profile.ptoEmi = '001'
      }
      if (!this.profile.secuenciales) {
        this.profile.secuenciales = {
          proforma: '1',
          notacompra: '1',
          notaventa: '1',
          faccompra: '1',
          facventa: '1',
        }
      }
    },
    methods: {
      clearProfile () {
        this.$store.dispatch('profile/setDataProfile')
      },
      pickFile () {
        this.$refs.image.click()
      },
      onFilePicked (e) {
        const files = e.target.files
        if (files[0] !== undefined) {
          this.imageName = files[0].name
          const extension = files[0].name.split('.')[1]
          if (extension.toString() !== 'png' && extension.toString() !== 'jpg' && extension.toString() !== 'jpeg') {
            this.snackbar = {
              show: true,
              color: 'red',
              text: 'El archivo a subir debe ser una imagen.',
            }
            this.dialog = false
            return
          }
          if (this.imageName.lastIndexOf('.') <= 0) {
            return
          }
          const fr = new FileReader()
          fr.readAsDataURL(files[0])
          fr.addEventListener('load', () => {
            this.imageUrl = fr.result
            this.imageFile = files[0]
          })
        } else {
          this.imageName = ''
          this.imageFile = ''
          this.imageUrl = ''
        }
      },
      upload () {
        this.userProfilePreview = this.profile
        const storageRef = firebase.storage().ref()
        const currentUser = firebase.auth().currentUser
        const mountainsRef = storageRef.child(`images-fac/${currentUser.uid}`)
        mountainsRef.put(this.imageFile).then(snapshot => {
          snapshot.ref.getDownloadURL().then(downloadURL => {
            this.imageUrl = downloadURL
            this.profile.urlImg = this.imageUrl
            firebase.database().ref(`users/${currentUser.uid}/urlImg`).set(
              this.imageUrl,
            )
            this.dialog = false
            this.snackbar = {
              show: true,
              color: 'blue',
              text: 'Para reflejar los cambios en las facturas debes presionar el botón GUARDAR CAMBIOS.',
            }
          })
        })
      },
      isNumber (evt) {
        evt = (evt) || window.event
        var charCode = (evt.which) ? evt.which : evt.keyCode
        if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
          evt.preventDefault()
        } else {
          return true
        }
      },
      async updateProfile () {
        this.dialogConfig = false
        if (parseFloat(this.profile.secuenciales.facventa) <= 0) {
          this.snackbar = {
            show: true,
            color: 'red',
            text: 'El secuencial de factura de venta debe ser mayor que cero.',
          }
          return
        }
        // if (parseFloat(this.profile.secuenciales.proforma) <= 0) {
        //   this.snackbar = {
        //     show: true,
        //     color: 'red',
        //     text: 'El secuencial de proforma debe ser mayor que cero.',
        //   }
        //   return
        // }
        // if (parseFloat(this.profile.secuenciales.notacompra) <= 0) {
        //   this.snackbar = {
        //     show: true,
        //     color: 'red',
        //     text: 'El secuencial de nota de compra debe ser mayor que cero.',
        //   }
        //   return
        // }
        // if (parseFloat(this.profile.secuenciales.notaventa) <= 0) {
        //   this.snackbar = {
        //     show: true,
        //     color: 'red',
        //     text: 'El secuencial de nota de venta debe ser mayor que cero.',
        //   }
        //   return
        // }
        if (!this.profile.urlImg || this.profile.urlImg.toString() === '') {
          this.snackbar = {
            show: true,
            color: 'red',
            text: 'Para continuar debe seleccionar el logo de la empresa.',
          }
          return
        } else if (this.profile.urlImg === 'https://firebasestorage.googleapis.com/v0/b/j2rinventor.appspot.com/o/default%2Fit_logo.png?alt=media&token=401e29bd-a347-440b-b369-94e75e5dd0fb') {
          this.snackbar = {
            show: true,
            color: 'red',
            text: 'Para continuar debe seleccionar el logo de la empresa.',
          }
          return
        }
        if (this.$refs.formProfile.validate()) {
          const lstFilter = this.lstProfileLoaded.filter(item => {
            return item.rucEstablecimieto === this.profile.rucEstablecimieto
          })
          let banNew = true
          if (lstFilter.length > 0) {
            if (this.profile.email !== lstFilter[0].email) {
              banNew = false
            }
          }
          if (!banNew) {
            this.snackbar = {
              show: true,
              color: 'red',
              text: 'El RUC ya se encuentra ingresado en otra cuenta.',
            }
            return
          }
          if (this.profile.estab.length < 3) {
            this.snackbar = {
              show: true,
              color: 'red',
              text: 'El código de establecimiento debe ser de 3 dígitos.',
            }
            return
          }
          if (this.profile.ptoEmi.length < 3) {
            this.snackbar = {
              show: true,
              color: 'red',
              text: 'El código del punto de emisión debe ser de 3 dígitos.',
            }
            return
          }
          // validacion para RUC o cedula
          let result = 0
          if (this.profile.rucEstablecimieto.length > 10) { // se validac omo RUC
            result = functions.validIdentNumberRUC(false, this.profile.rucEstablecimieto)
          } else { // se valida como cedula
            this.snackbar = {
              show: true,
              color: 'red',
              text: 'El RUC debe ser de 13 dígitos.',
            }
            return
          }
          if (!this.$store.state.facturacion.tokenAdmin) {
            await this.$store.dispatch('facturacion/adminLoginFac')
          }
          if (result.toString() === '1') {
            this.loading = true
            let logoBase64 = ''
            this.$store.dispatch('facturacion/getComanyImg', this.profile.urlImg)
              .then(dataUrl => {
                logoBase64 = dataUrl.split(',')[1]
                const data = {
                  token: this.$store.state.facturacion.tokenAdmin,
                  body: {
                    rucPropietario: this.profile.rucEstablecimieto,
                    razonSocial: this.profile.razonSocial,
                    tipoIdentificacion: 'RUC',
                    logo: logoBase64,
                  },
                }
                this.$store.dispatch('facturacion/createCompany', data).then(res => {
                  if (res) {
                    this.$store.dispatch('profile/setProfile', this.profile).then(async resolve => {
                      this.loading = false
                      this.snackbar = {
                        show: true,
                        color: 'green',
                        text: 'Perfíl guardado correctamente',
                      }
                    }, reject => {
                      this.loading = false
                      this.clearProfile()
                      this.snackbar = {
                        show: true,
                        color: 'red',
                        text: 'Error al guardar el perfil',
                      }
                    })
                  } else {
                    this.loading = false
                    this.clearProfile()
                    this.snackbar = {
                      show: true,
                      color: 'red',
                      text: 'Error al momento de realizar el proceso.',
                    }
                  }
                }, reject => {
                  this.loading = false
                  this.clearProfile()
                  if (reject.response.data.message) {
                    this.snackbar = {
                      show: true,
                      color: 'red',
                      text: reject.response.data.message,
                    }
                    return
                  }
                  this.loading = false
                  this.snackbar = {
                    show: true,
                    color: 'red',
                    text: 'Error al momento de realizar el proceso.',
                  }
                })
              })
          } else {
            this.snackbar = {
              show: true,
              color: 'red',
              text: 'Verifique el número de RUC agregado',
            }
          }
        }
      },
    },
  }
</script>
